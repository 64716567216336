import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { installGoogleAuth } from "@/core/config/GoogleAuth";
import { initFacebook, login } from "@/core/config/FacebookAuth";
export default defineComponent({
    name: "BaseSocialLogin",
    setup: function () {
        var _this = this;
        var store = useStore();
        var gAuth;
        var user = ref({});
        var loading = ref({
            google: false,
            facebook: false,
            apple: false,
        });
        var options = {
            clientId: process.env.VUE_APP_GOOGLE_KEY,
            scope: "profile email",
            prompt: "select_account",
        };
        var loginWithFacebook = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                //Comming soon
                alert("Coming Soon!");
                return [2 /*return*/];
            });
        }); };
        function loginWithGoogle() {
            //Comming soon
            alert("Coming Soon!");
            // let userToken = "";
            // if (!gAuth) return;
            // gAuth
            //   .signIn()
            //   .then((googleUser: any) => {
            //     userToken = googleUser.wc.access_token;
            //     submitLogin(
            //       {
            //         provider: "google",
            //         provider_token: userToken,
            //       },
            //       "google"
            //     );
            //   })
            //   .catch(() => {
            //     console.error("google_submit", "error");
            //   });
        }
        var loginWithApple = function () {
            alert("Coming Soon!");
        };
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, installGoogleAuth(options)];
                    case 1:
                        gAuth = _a.sent();
                        initFacebook(process.env.VUE_APP_FACEBOOK_KEY);
                        return [2 /*return*/];
                }
            });
        }); });
        var submitLogin = function (values, type) { return __awaiter(_this, void 0, void 0, function () {
            var action;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Clear existing errors
                        store.dispatch(Actions.LOGOUT);
                        // Send login request
                        loading.value[type] = true;
                        action = "";
                        if (type === "normal") {
                            action = Actions.LOGIN;
                        }
                        else if (type === "google" || type === "facebook") {
                            action = Actions.LOGIN_BY_SOCIAL;
                        }
                        return [4 /*yield*/, store.dispatch(action, values).catch()];
                    case 1:
                        _a.sent();
                        loading.value[type] = false;
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            login: login,
            loginWithGoogle: loginWithGoogle,
            user: user,
            loginWithFacebook: loginWithFacebook,
            loading: loading,
            loginWithApple: loginWithApple,
        };
    },
});
