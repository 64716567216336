import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_BaseIcon = _resolveComponent("BaseIcon");
    var _component_BaseFlex = _resolveComponent("BaseFlex");
    return (_openBlock(), _createBlock(_component_BaseFlex, { class: "justify-content-center" }, {
        default: _withCtx(function () { return [
            _createVNode(_component_BaseIcon, {
                class: "px-5",
                iconName: "bi bi-google",
                onClick: _ctx.loginWithGoogle,
                isGreenBackground: ""
            }, null, 8, ["onClick"]),
            _createVNode(_component_BaseIcon, {
                class: "px-5",
                iconName: "bi bi-facebook",
                onClick: _ctx.loginWithFacebook,
                isGreenBackground: ""
            }, null, 8, ["onClick"]),
            _createVNode(_component_BaseIcon, {
                onClick: _ctx.loginWithApple,
                class: "px-5",
                iconName: "bi bi-apple",
                isGreenBackground: ""
            }, null, 8, ["onClick"])
        ]; }),
        _: 1
    }));
}
